//
// Menu
//

// Aside menu
.docs-aside-menu {
    .menu {
        // Link padding x
        @include menu-link-padding-x($docs-aside-padding-x);

        // Menu indention
        @include menu-link-indention($docs-aside-menu-indention, $docs-aside-padding-x);
    }

    // Item
    .menu-item {
        padding: 0;

        // Menu Link
        .menu-link {
            font-weight: 500;
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
        }

        // Icon
        .menu-icon {
            justify-content: flex-start;
        }
    }
}
