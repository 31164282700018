//
// Pagination
//

.pagination {
    --#{$prefix}pagination-color: var(--kt-pagination-color);
    --#{$prefix}pagination-bg: var(--kt-pagination-bg);
    --#{$prefix}pagination-border-color: var(--kt-pagination-border-color);
    --#{$prefix}pagination-hover-color: var(--kt-pagination-hover-color);
    --#{$prefix}pagination-hover-bg: var(--kt-pagination-hover-bg);
    --#{$prefix}pagination-hover-border-color: var(--kt-pagination-hover-border-color);
    --#{$prefix}pagination-focus-color: var(--kt-pagination-focus-color);
    --#{$prefix}pagination-focus-bg: var(--kt-pagination-focus-bg);
    --#{$prefix}pagination-focus-box-shadow: var(--kt-pagination-focus-box-shadow);
    --#{$prefix}pagination-active-color: var(--kt-pagination-active-color);
    --#{$prefix}pagination-active-bg: var(--kt-pagination-active-bg);
    --#{$prefix}pagination-active-border-color: var(--kt-pagination-active-border-color);
    --#{$prefix}pagination-disabled-color: var(--kt-pagination-disabled-color);
    --#{$prefix}pagination-disabled-bg: var(--kt-pagination-disabled-bg);
    --#{$prefix}pagination-disabled-border-color: var(--kt-pagination-disabled-border-color);

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;

    // Pagination circle
    &.pagination-circle {
        .page-link {
            border-radius: 50%;
        }
    }

    // Pagination outline
    &.pagination-outline {
        .page-link {
            border: 1px solid var(--kt-border-color);
        }

        .page-item {
            &:hover:not(.disabled),
            &.active {
                .page-link {
                    border-color: var(--kt-primary-light);
                }
            }
        }
    }
}

.page-item {
    margin-right: $pagination-item-space;

    &:last-child {
        margin-right: 0;
    }

    .page-link {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $btn-border-radius;
        height: $pagination-item-height;
        width: $pagination-item-height;
        font-weight: $pagination-font-weight;
        font-size: $pagination-font-size;

        i {
            font-size: $pagination-icon-font-size;
        }

        /*rtl:options:{"autoRename":false}*/
        .previous,
        .next {
            display: block;
            height: $pagination-icon-height;
            width: $pagination-icon-height;

            /*rtl:raw:transform: rotateZ(-180deg);*/
        }

        /*rtl:end:ignore*/

        .previous {
            @include svg-bg-icon(arrow-start, $pagination-color);
        }

        /*rtl:options:{"autoRename":false}*/
        .next {
            @include svg-bg-icon(arrow-end, $pagination-color);
        }
    }

    &:focus {
        .page-link {
            color: var(--kt-pagination-focus-color);

            .svg-icon,
            i {
                color: var(--kt-pagination-focus-color);
            }

            .previous {
                @include svg-bg-icon(arrow-start, var(--kt-pagination-focus-color));
            }

            /*rtl:options:{"autoRename":false}*/
            .next {
                @include svg-bg-icon(arrow-end, var(--kt-pagination-focus-color));
            }
        }
    }

    &:hover:not(.active):not(.offset):not(.disabled) {
        .page-link {
            color: var(--kt-pagination-hover-color);

            &.page-text {
                background-color: transparent;
            }

            .svg-icon,
            i {
                color: var(--kt-pagination-hover-color);
            }

            .previous {
                @include svg-bg-icon(arrow-start, var(--kt-pagination-hover-color));
            }

            /*rtl:options:{"autoRename":false}*/
            .next {
                @include svg-bg-icon(arrow-end, var(--kt-pagination-hover-color));
            }
        }
    }

    &.active {
        .page-link {
            color: var(--kt-pagination-active-color);

            &.page-text {
                background-color: transparent;
            }

            .svg-icon,
            i {
                color: var(--kt-pagination-active-color);;
            }

            .previous {
                @include svg-bg-icon(arrow-start, var(--kt-pagination-active-color));
            }

            /*rtl:options:{"autoRename":false}*/
            .next {
                @include svg-bg-icon(arrow-end, var(--kt-pagination-active-color));
            }
        }
    }

    &.disabled {
        .page-link {
            color: var(--kt-pagination-disabled-color);

            .svg-icon,
            i {
                color: var(--kt-pagination-disabled-color);
            }

            .previous {
                @include svg-bg-icon(arrow-start, var(--kt-pagination-disabled-color));
            }

            /*rtl:options:{"autoRename":false}*/
            .next {
                @include svg-bg-icon(arrow-end, var(--kt-pagination-disabled-color));
            }
        }
    }

    /*rtl:end:ignore*/
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
    .page-item {
        margin-right: $pagination-item-space-tablet-and-mobile;

        &:last-child {
            margin-right: 0;
        }
    }
}

