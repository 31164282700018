// Image thumbnails
.img-thumbnail {
    background-color: var(--kt-thumbnail-bg);
    border: $thumbnail-border-width solid var(--kt-thumbnail-border-color);
    @include box-shadow(var(--kt-thumbnail-box-shadow));
}

.figure-caption {
    color: var(--kt-figure-caption-color);
}
